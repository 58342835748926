import React from "react";
import Layout from '../components/layout';
import indexStyles from './index.module.scss';
import Title from '../components/title';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IndexPage = () => {
  return (
    <Layout title="US Safety Equipment | Purchase Safety Equipment Online">
      <div className="container">
        <div className={indexStyles.top}>
          <div className="columns is-half">
            <div className={`column ${indexStyles.contactWrap}`}>
              <img src="/images/phone-icon.png" alt="phone icon"/>
              <h3><span className="is-hidden-touch">CALL US:</span> 1-800-331-2973</h3>
            </div>
            <div className={`column ${indexStyles.contactWrap}`}>
            <img src="/images/email-icon.png" alt="@ icon"/>
              <h3><span className="is-hidden-touch">EMAIL US:</span> NTGProducts@yahoo.com</h3>
            </div>
          </div>
        </div>
        <Title>QUICK-SHIP<br />PRODUCT CATEGORIES</Title>

        <div>
          <div className={`${indexStyles.frontTile} even`}>
            <Link to="/body-harnesses/">
              <div className="content-wrap">

                <img src="/images/cat/harnesses.jpg" alt="Safety Harnesses"/>
                <div className={`${indexStyles.colorBlock}`}>
                  <h2><span className={indexStyles.shop}>SHOP</span><br />BODY HARNESSES</h2>

                </div>
              </div>

            </Link>
          </div>
          <div className={`${indexStyles.frontTile} odd`}>
            <Link to="/lanyards-and-lifelines/">
              <div className="content-wrap"><img src="/images/cat/lanyards.jpg" alt="Safety Lanyards and Lifelines" />
                <div className={`${indexStyles.colorBlock}`}>
                  <h2><span className={indexStyles.shop}>SHOP</span><br />LANYARDS & LIFELINES</h2>

                </div>
              </div>

            </Link>
          </div>
          <div className={`${indexStyles.frontTile} even`}>
            <Link to="/anchors-and-positioning/">
              <div className="content-wrap"><img src="/images/cat/anchors.jpg" alt="Anchors and Positioning Devices"/>
                <div className={`${indexStyles.colorBlock}`}>
                  <h2><span className={indexStyles.shop}>SHOP</span><br />ANCHORS &amp; POSITIONING</h2>

                </div>
              </div>

            </Link>
          </div>
        </div>

        <div className={indexStyles.bottom}>
          <p className="has-text-centered">Our online store only shows products that are available for immediate shipment. View our full product line-up by downloading our catalog <a target="_blank" href="pdf/US-Safety-Equipment-Catalog.pdf">here.</a></p>
          <a target="_blank" href="pdf/US-Safety-Equipment-Catalog.pdf">
            <img src="images/catalog-banner.jpg" alt="Download our full product catalog here"/>
          
          </a>
        </div>
      </div>

    </Layout>
  )

}

export default IndexPage;